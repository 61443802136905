import { Controller } from '@hotwired/stimulus';
import { removeElement } from '../helpers';

export default class extends Controller {
  static targets = ['content', 'contentDiv', 'toggle'];
  static values = {
    height: { default: 96, type: Number },
  };

  connect() {
    const toggleExist = this.hasToggleTarget;

    if(toggleExist)
      this.toggleTarget.remove();

    if (this.contentTarget.clientHeight > this.heightValue || toggleExist) {
      this.toggleLink('show more', 'down', 'show');
      this.applyLineClamp();
    }
  }

  toggleLink(text, icon, action) {
    const linkTemplate = `
      <div class="text-greyBlack flex items-center inline-block w-full text-left mt-3 mb-1"
          data-guest--read-more-less-target="toggle"
          data-action="click->guest--read-more-less#${action}">
        ${text} &nbsp
        <i class="fas fa-chevron-${icon} mr-1 pointer-events-none"></i>
      </div>
    `;
    this.contentTarget.insertAdjacentHTML('afterend', linkTemplate);
    this.contentTarget.classList.toggle('line-clamp-4');
    this.dispatch('changed')
  }

  show(event) {
    this.removeTarget(event)
    if (this.contentDivTarget.childElementCount <= 1) {
      this.toggleLink('show less', 'up', 'hide');
    }
  }

  hide(event) {
    this.removeTarget(event)
    if (this.contentDivTarget.childElementCount <= 1) {
      this.toggleLink('show more', 'down', 'show');
      this.scrollToTop();
    }
  }

  removeTarget(event) {
    event.preventDefault();
    removeElement(event.target);
  }

  scrollToTop() {
    window.scrollTo({ top: 0 });
  }

  applyLineClamp() {
    this.contentTarget.classList.add('line-clamp-4', 'overflow-hidden');
  }
}
