import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { baseClass: String, showAfter: Number }


  constructor(context) {
    super(context)
    this.buttons = null
  }

  connect() {
    if (this.hasShowAfterValue) {
      this.DelayLoading()
    }

    this.buttons = this.element.querySelectorAll("button")
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const category_param = urlParams.get("category")
    
    if (this.baseClassValue == 'listing') {
      this.toggleActiveButton(this.buttons[0])
    }

    if (category_param != null) {
      const selected_category_button = document.getElementById(
        "guest_listing_category-" + category_param,
      )
      this.toggleActiveButton(selected_category_button)

      // For unknown reasons scrollIntoView won't work in Turbo until wrap it within setTimeout
      setTimeout(() => {
        // Scroll to selected category from [category] param
        selected_category_button.scrollIntoView({block: "end", inline: "nearest"})
      }, this.refreshIntervalValue)
    }
  }

  DelayLoading() {
    setTimeout(() => {
      this.element.classList.remove('hidden')
    }, this.refreshIntervalValue)
  }

  redirectToListingCategory(event) {
    Turbo.visit(event.params.url)
  }

  toggleActiveButton(btnTarget) {
    btnTarget.classList.add("bg-greyBlack")
    btnTarget.classList.add("text-guestOrange")
    btnTarget.classList.remove("bg-white")

    const filteredBtnList = Array.from(this.buttons).filter(
      (button) => button !== btnTarget,
    )

    for (let btn of filteredBtnList) {
      btn.classList.add("bg-white")
      btn.classList.remove("bg-greyBlack")
      btn.classList.remove("text-guestOrange")
    }
  }
}
