import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["backToTopButton"];

  connect() {
    this.scrollHandler = this.handleScroll.bind(this);
    this.element.classList.add("opacity-0", "translate-y-24");

    document.addEventListener("scroll", this.scrollHandler);
  }

  scrollToTop(_event) {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  handleScroll(_event) {
    const position = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    if ((document.documentElement.scrollTop / position) > 0.40) {
      this.element.classList.remove("opacity-0", "translate-y-24");
    } else {
      this.element.classList.add("opacity-0", "translate-y-24");
    }
  }
}
