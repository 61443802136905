import { Controller } from '@hotwired/stimulus';
import { removeElement } from '../helpers';

export default class extends Controller {
  static targets = ['content'];
  static values = { 
    height: { default: 96, type: Number },
    heightClass: { default: "h-24", type: String },
    linkClass: { default: "text-sm", type: String }
  };

  connect() {
    if (this.contentTarget.clientHeight > this.heightValue) {
      this.contentTarget.classList.add("text-clip", this.heightClassValue, "overflow-hidden");
      this.contentTarget.insertAdjacentHTML('afterend', this.readMoreLinkTemplate);
    }
  }

  show(event) {
    event.preventDefault();

    this.contentTarget.classList.remove(this.heightClassValue);
    removeElement(event.target);
  }

  get readMoreLinkTemplate() {
    return `
      <a class="${this.linkClassValue} font-bold inline-block w-full text-right mt-2"
         href="#"
         data-action="click->guest--read-more#show">
        Read More
      </a>
    `;
  }
}
