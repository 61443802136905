// This will persisting horizontal scrollbars positions
// for Simplebar when navigating with Turbo
//
// It can be updated for normal scrollbars by adding [data-turbo-permanent] on wrapper element
// and query them inside turbo:before-render and turbo:render listeners

var scrollPositions = {};
addEventListener("turbo:before-render", function(event){
  document.querySelectorAll("div.simplebar-content-wrapper").forEach(function(element){ 
    scrollPositions[element.id] = element.scrollLeft;
  });
});

addEventListener("turbo:render", function(){
  document.querySelectorAll("div.simplebar-content-wrapper").forEach(function(element){ 
    element.scrollLeft = scrollPositions[element.id];
  });
});