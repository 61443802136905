import { Controller } from '@hotwired/stimulus';
import { format, parse } from "date-fns";

let start_date, end_date = ''

export default class extends Controller {
  static targets = ["startDate", "endDate", "dataTable"]

  connect() {
    this.startDateTarget.addEventListener('changeDate', this.handleInputChange.bind(this));
    this.endDateTarget.addEventListener('changeDate', this.handleInputChange.bind(this));
  }
  
  get getDateRange() {
    start_date = parse(this.startDateTarget.value, "MM/dd/yyyy", new Date())
    start_date = format(start_date, 'yyyy-MM-dd')

    end_date = parse(this.endDateTarget.value, "MM/dd/yyyy", new Date())
    end_date = format(end_date, 'yyyy-MM-dd')
  }

  handleInputChange(event) {
    this.getDateRange

    var currentUrl = new URL(window.location.href);
    const dataTable = this.dataTableTarget  

    currentUrl.searchParams.set('start_date', start_date);
    currentUrl.searchParams.set('end_date', end_date);
    const csv_data_url = currentUrl.toString();
  
    Rails.ajax({
      url: csv_data_url,
      type: "get",
      dataType: "script",
      success: (data) => {
        dataTable.innerHTML = data;
        
      }
    });
  }

  exportToCsv(event) {
    this.getDateRange
    
    var currentUrl = new URL(window.location.href);
    const dataTable = this.dataTableTarget  

    currentUrl.searchParams.set('start_date', start_date);
    currentUrl.searchParams.set('end_date', end_date);
    currentUrl.pathname += '.csv';
    const csv_data_url = currentUrl.toString();
      
    const tableType = event.params.tabletype;

    Rails.ajax({
      url: csv_data_url,
      type: "get",
      dataType: "csv",
      success: (data) => {
        const filename = `houstr_${tableType}_from_${start_date}_to_${end_date}.csv`;
        this.downloadCSV(data, filename);
      }
    });
  }

  downloadCSV(data, filename) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const csv_link = document.createElement('a');
    csv_link.style.display = 'none';
    csv_link.href = url;
    csv_link.download = filename;
    
    document.body.appendChild(csv_link);
    csv_link.click();
    
    window.URL.revokeObjectURL(url);
    document.body.removeChild(csv_link);
  }
}