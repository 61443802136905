import Cookies from 'js-cookie'
import ahoy from "ahoy.js"

// Segment's write key is parsed from meta tag inside <head> tag.
const segment_key = document.querySelector("meta[name='segment-key']")?.content

!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="<%= HI %>";;analytics.SNIPPET_VERSION="4.16.1";
  if (Cookies.get('anonymous_id')) {
    analytics.setAnonymousId(Cookies.get('anonymous_id'));
  }
  if (segment_key){
    analytics.load(segment_key)
  }
}}();

export default class Analytics {
  static track(event_name, event_data = {}) {
    if (segment_key != null) {
      analytics.track(event_name, event_data)
    }
    ahoy.track(event_name, event_data)
  }
}

window.Analytics = Analytics