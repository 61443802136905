import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.dirty = false;
    this.eventHandler = this.turboVisitHandler.bind(this);

    // Attach the controller to the DOM element so code outside of this controller can call this.permitSubmit();
    this.element['controller'] = this;

    document.addEventListener("turbo:before-visit", this.eventHandler);

    if (this.element.elements) {
      // On a <form> tag, `elements` returns an HTMLFormControlsCollection of all form elements.
      Array.from(this.element.elements).forEach(function(formElement) {
        formElement.addEventListener("change", function() {
          this.dirty = true;
        }.bind(this));
      }.bind(this));
    }

    // Permit submission when a user submits the form (e.g. by pressing enter in a form field)
    this.element.addEventListener('submit', function(event) {
      this.dirty = false;
    }.bind(this));

    document.addEventListener('turbo:submit-start', function(event) {
      this.dirty = false;
    }.bind(this));
  }

  disconnect() {
    // Ensure that the event listener is removed when the form is no longer on the page.
    this.removeEventListener();
    // Reset form fields to prevent caching values when navigating away
    this.resetForm()
  }

  permitSubmit(_event) {
    this.dirty = false;
  }

  cancelSave(event) {
    event.preventDefault();
    Turbo.visit(this.navigatedUrl);
  }

  resetForm() {
    this.element.reset()
  }

  confirmSave(event) {
    event.preventDefault();
    this.element.submit();
  }

  // Private
  turboVisitHandler(event) {
    if (this.dirty) {
      // Prevent the navigation and show the confirmation modal.
      event.preventDefault();
      this.navigatedUrl = event.detail.url;
      this.removeEventListener();
      this.showModal();
    }
  }

  removeEventListener() {
    document.removeEventListener("turbo:before-visit", this.eventHandler);
  }

  showModal() {
    this.modalElement = document.createElement('div');
    this.modalElement.innerHTML = this.modalTemplate();
    this.element.appendChild(this.modalElement);

    document.body.insertAdjacentHTML('beforeend', this.backdropTemplate());
  }

  modalTemplate() {
    return `
      <div data-controller="modal" data-modal-allow-background-close="false">
        <!-- Modal Container -->
        <div data-modal-target="container" data-action="click->modal#closeBackground keyup@window->modal#closeWithKeyboard" class="animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center" style="z-index: 9999;">
          <!-- Modal Inner Container -->
          <div class="max-h-screen w-full max-w-lg relative">
            <!-- Modal Card -->
            <div class="m-1 bg-white rounded shadow">
              <div class="p-8">
                <h2 class="text-xl mb-4">Unsaved changes</h2>
                <p class="mb-4">Do you want to save your changes?</p>

                <div class="flex gap-2 justify-end items-center flex-wrap mt-6">
                  <button class="btn btn-primary" data-action="click->form-dirty-tracking#cancelSave">No</button>
                  <button class="btn btn-primary" data-action="click->form-dirty-tracking#confirmSave">Yes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  backdropTemplate() {
    return `<div id="modal-background" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>`;
  }
}
