import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {
  static targets = ['streetAddress'];

  connect() {
    super.connect()
  }

  placeChanged() {
    super.placeChanged()
    const addressComponents = this.place.address_components
    const formattedAddress = this.formatAddressComponents(addressComponents)

    this.streetAddressTarget.value = `${formattedAddress.street_number} ${formattedAddress.route}`
  }
}
