import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['propertyModal', 'emailField', 'emailMessage', 'emailForm'];

  showPropertyModal(event) {
    event.preventDefault();
    const modal = this.propertyModalTarget;
    modal.classList.toggle('hidden');
  }

  closeModal(event) {
    const modal = this.propertyModalTarget;
    modal.classList.toggle('hidden');
  }

  hideEmailForm() {
    this.emailMessageTarget.classList.toggle("text-sm");
    this.emailMessageTarget.classList.toggle("text-xl");
    this.emailMessageTarget.classList.toggle("font-bold");
    this.emailMessageTarget.classList.toggle("text-center");
    this.emailFormTarget.classList.toggle("hidden");
    this.emailMessageTarget.textContent = "Check your email for your receipt!"
  }

  preventPropagation(event) {
    event.stopPropagation();
  }

}
