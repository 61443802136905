import { Controller } from "@hotwired/stimulus";
import Pikaday from "pikaday";
import { format, parse } from "date-fns";

export default class extends Controller {
  static targets = ["input", "hidden"];
  static values = { min: String, max: String };

  connect() {
    this.picker = new Pikaday({
      field: this.inputTarget,
      format: "MM / dd / yyyy",
      minDate: parse(this.minValue, "yyyy-MM-dd", new Date()),
      maxDate: parse(this.maxValue, "yyyy-MM-dd", new Date()),
      toString: function(date, formatString) {
        return format(date, formatString);
      },
      parse: function(dateString, formatString) {
        return parse(dateString, formatString, new Date());
      },
      onSelect: function(date) {
        console.log("date: ", date);
        this.hiddenTarget.value = format(date, "yyyy-MM-dd");

        this.hiddenTarget.form.submit();
      }.bind(this),
      theme: "houstr-theme",
      i18n: {
        // ...Pikaday.defaults.i18n,
        previousMonth : 'Previous Month',
        nextMonth     : 'Next Month',
        months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
        weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
        weekdaysShort: ["S", "M", "T", "W", "T", "F", "S"],
      }
    });
  }

  show() {
    if (!this.picker.isVisible()) {
      this.picker.show();
    }
  }
}
