// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import { Controller } from "@hotwired/stimulus"
import "@gorails/ninja-keys"

export default class extends Controller {
  connect() {
    this.element.data = [
      {
        id: "Guest Stores",
        title: "Guest Stores",
        hotkey: "ctrl+L",
        icon: '<svg width="24" height="24" viewBox="0 0 28 28" class="ninja-icon" xmlns="http://www.w3.org/2000/svg"><path d="m27.066 12.922-12.48-9.766a.65.65 0 0 0-.845 0L1.258 12.923a.696.696 0 0 0-.253.46.675.675 0 0 0 .139.507.69.69 0 0 0 .959.118l2.493-1.967v11.482c0 .382.32.704.698.704H23.06a.71.71 0 0 0 .698-.704V12.07l2.494 1.968a.712.712 0 0 0 .97-.101l.028-.043c.168-.332.09-.748-.185-.972Zm-4.731-1.938V22.85H5.964V10.984l8.185-6.419 8.186 6.42Z" fill="inherit"/><path d="M9.67 10.972a1.596 1.596 0 0 0-.508 1.14v7.645c0 .82.687 1.513 1.5 1.513h7.002c.813 0 1.5-.693 1.5-1.513v-7.673a1.6 1.6 0 0 0-.512-1.145l-3.513-3.068a1.534 1.534 0 0 0-1.981.002l-3.489 3.1Zm.777 1.112c0-.047.024-.111.06-.134l3.495-3.102a.363.363 0 0 1 .147-.05c.045 0 .093.022.14.045l3.49 3.097c.073.074.073.126.073.144v7.673c0 .12-.097.217-.215.217h-6.974a.217.217 0 0 1-.216-.217v-7.673Z" fill="inherit"/><path d="M14.15 12.012c.479 0 .87-.393.87-.877a.874.874 0 0 0-.87-.877c-.48 0-.87.393-.87.877s.39.877.87.877ZM13.956 16.566h.415c.078 0 .165.041.233.11.054.055.08.132.081.25-.025.162-.164.274-.342.274h-.47a.164.164 0 0 1-.151-.118.31.31 0 0 0-.32-.245h-.553c-.062 0-.184.026-.24.112l-.011.016c-.072.082-.072.167-.072.225l.002.02a1.363 1.363 0 0 0 1.05 1.18v.363a.32.32 0 0 0 .323.325h.553a.32.32 0 0 0 .322-.325v-.366a1.512 1.512 0 0 0 1.107-1.627c-.088-.77-.777-1.374-1.567-1.374h-.36a.344.344 0 0 1-.233-.11c-.054-.054-.08-.132-.081-.25.025-.161.164-.274.342-.274h.47c.067 0 .135.053.151.119a.31.31 0 0 0 .32.244h.553c.062 0 .184-.025.24-.112l.011-.016c.071-.081.071-.167.071-.225v-.02a1.363 1.363 0 0 0-1.051-1.18v-.39a.32.32 0 0 0-.323-.325h-.553a.32.32 0 0 0-.323.325v.368c-.61.173-1.05.696-1.107 1.33-.03.464.103.875.386 1.189.292.322.702.507 1.127.507Z" fill="inherit"/></svg>',
        handler: () => {
          Turbo.visit("/listings")
        }
      },
      {
        id: "Catalog",
        title: "Catalog",
        hotkey: "ctrl+P",
        icon: '<svg width="24" height="24" viewBox="0 0 28 28" class="ninja-icon" xmlns="http://www.w3.org/2000/svg"><path d="M22.894 5.457 14.9 3 7.034 5.214v4.582L3 10.932v7.756l7.19 2.607.012.015L17.62 24 26 21.364v-7.59l-3.106-.954V5.457ZM7.582 14.86v-1.449l2.306.92v5.688L4.2 18.104v-6.04l2.182.869v1.43l1.2.497Zm-.549-2.756-1.872-.746 1.872-.527v1.273Zm8.663 3.207 5.885-1.851 2.16.664-6.095 1.964-1.95-.777Zm-.575-.95V8.635l6.576-2.12v5.913l-6.576 1.935Zm3.284-9.24 2.23.685L14.54 7.77l-1.998-.797 5.863-1.853Zm-7.066 9.541 2.183.87v1.43l1.2.497v-1.448l2.305.919v5.689l-5.688-1.916v-6.04Zm-.263-8.273-1.881-.75 5.687-1.6 1.84.566-5.646 1.784Zm-.66.826v1.43l1.2.497V7.693l2.305.92V14.3l-5.687-1.915V6.345l2.182.87Zm7.811 15.464V16.95l6.577-2.12v5.913l-6.577 1.935Z" fill="inherit"/></svg>',
        handler: () => {
          Turbo.visit("/products")
        }
      },
      {
        id: "Profile",
        title: "Profile",
        hotkey: "",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
        handler: () => {
          Turbo.visit("/users/edit")
        }
      },
      {
        id: "My sales",
        title: "My sales",
        hotkey: "ctrl+S",
        icon: '<svg width="24" height="24" viewBox="0 0 28 28" class="ninja-icon" xmlns="http://www.w3.org/2000/svg"><path d="M24.372 3H19.2a.616.616 0 0 0-.629.602v4.347h-4.597a.616.616 0 0 0-.629.602V12.9H8.802a.617.617 0 0 0-.629.602v4.347H3.629A.617.617 0 0 0 3 18.45v4.948c0 .332.282.602.629.602h15.524l.02-.002 5.198.002c.347 0 .629-.27.629-.602V3.602A.616.616 0 0 0 24.372 3Zm-.628 1.203v18.592h-3.916V4.203h3.916Zm-5.226 4.948v13.644h-3.915V9.151h3.915ZM13.346 14.1v8.695H9.43V14.1h3.915Zm-5.173 4.949v3.746H4.258v-3.746h3.915ZM9.757 7.582h.48c.09 0 .191.04.27.105.063.053.092.127.094.24-.03.157-.19.265-.396.265h-.544c-.078 0-.157-.052-.175-.115-.044-.147-.183-.235-.37-.235h-.641c-.072 0-.213.025-.278.108l-.013.016c-.083.078-.083.16-.083.216l.002.02c.066.552.57 1.018 1.216 1.136v.349c0 .175.165.313.374.313h.64c.235 0 .374-.16.374-.313v-.352c.823-.18 1.38-.852 1.282-1.567-.102-.742-.9-1.323-1.815-1.323h-.417c-.09 0-.191-.04-.27-.106-.063-.052-.092-.127-.094-.24.03-.156.19-.264.396-.264h.545c.077 0 .156.05.175.114.043.147.182.235.37.235h.64c.072 0 .212-.024.278-.107l.013-.016c.083-.079.083-.161.083-.217l-.002-.02c-.066-.552-.57-1.017-1.216-1.136v-.375c0-.196-.19-.313-.374-.313h-.64c-.21 0-.374.138-.374.313v.354c-.706.167-1.216.67-1.282 1.281-.035.447.12.843.447 1.145.337.31.813.489 1.305.489Z" fill="inherit"/></svg>',
        handler: () => {
          Turbo.visit("/sales")
        }
      },
      {
        id: "Support",
        title: "Support",
        hotkey: "",
        icon: '<svg width="24" height="24" viewBox="0 0 28 28" class="ninja-icon" xmlns="http://www.w3.org/2000/svg"><path d="M21.68 14.304a.749.749 0 0 0 .766.022.762.762 0 0 0 .39-.666V7.131c0-.924-.746-1.676-1.663-1.676H16.83v-.779c0-.924-.746-1.676-1.663-1.676H7.235c-.916 0-1.662.752-1.662 1.676v6.53c0 .277.15.532.39.666.235.13.536.122.766-.023l1.438-.913h5.082v.778c0 .924.746 1.676 1.663 1.676h5.33l1.438.914Zm-7.203-7.173c0-.242.195-.44.435-.44h6.261c.24 0 .436.198.436.44v5.666l-.797-.507a.877.877 0 0 0-.47-.137h-5.43a.438.438 0 0 1-.435-.439V7.131Zm.435-1.676A1.672 1.672 0 0 0 13.25 7.13V9.7H8.067a.88.88 0 0 0-.471.137l-.797.506V4.677c0-.243.195-.44.436-.44h7.931c.24 0 .436.197.436.44v.778h-.69ZM10.427 16.748c0-1.432-1.156-2.597-2.576-2.597-1.42 0-2.576 1.165-2.576 2.597s1.156 2.597 2.576 2.597c1.42 0 2.576-1.165 2.576-2.597Zm-1.227 0c0 .75-.605 1.36-1.349 1.36s-1.349-.61-1.349-1.36c0-.75.605-1.36 1.35-1.36.743 0 1.348.61 1.348 1.36ZM9.264 19.768H6.439C4.543 19.768 3 21.323 3 23.235v1.319h9.702v-1.32c0-1.911-1.542-3.466-3.438-3.466Zm-2.825 1.237h2.825c1.22 0 2.211 1 2.211 2.23v.081H4.227v-.081c0-1.23.992-2.23 2.212-2.23Z" fill="inherit"/><path d="M20.524 14.15c-1.42 0-2.576 1.166-2.576 2.598s1.155 2.597 2.576 2.597c1.42 0 2.575-1.165 2.575-2.597s-1.155-2.597-2.575-2.597Zm1.348 2.598c0 .75-.605 1.36-1.348 1.36-.744 0-1.35-.61-1.35-1.36 0-.75.606-1.36 1.35-1.36.743 0 1.348.61 1.348 1.36ZM21.936 19.768h-2.824c-1.896 0-3.439 1.555-3.439 3.467v1.319h9.702v-1.32c0-1.911-1.543-3.466-3.439-3.466ZM16.9 23.317v-.082c0-1.23.992-2.23 2.212-2.23h2.824c1.22 0 2.212 1 2.212 2.23v.082H16.9Z" fill="inherit"/></svg>',
        handler: () => {
          Turbo.visit("/support_topics")
        }
      },
    ];
  }
}
