import Chart from "stimulus-chartjs";
import { format, parse } from "date-fns";

export default class extends Chart {
  static values = { formatAsCurrency: { type: Boolean, default: false } };

  get defaultOptions() {
    return {
      elements: {
        point: {pointStyle: "circle"}
        // point: {pointStyle: "line"} // To make points invisible, but still accessible via mouse hover.
      },
      scales: {
        xAxis: {
          type: "time",
          grid: {display: false, drawBorder: false, drawTicks: false},
          ticks: {
            color: "#C3C3C3",
            padding: 16,
            source: "auto"
            // source: "labels"
          },
          time: {
            stepSize: 7,
            unit: 'day',
            // displayFormats: {
              // day: 'dd'
            // }
          }
        },
        yAxis: {
          grid: {drawBorder: false, drawTicks: false},
          ticks: {
            count: 4,
            precision: 0,
            color: "#C3C3C3",
            padding: 10,
            callback: function(value, index, ticks) {
              if (this.formatAsCurrencyValue === true) {
                return `$${value}`;
              }
              return value;
            }.bind(this)
          }
        }
      },
      plugins: {
        legend: {display: false},

        // Customize our tooltips styles.
        // If we need deeper customization, we can build our own DOM elements instead:
        // https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
        tooltip: {
          backgroundColor: "#ffffff",
          borderColor: "#e76f51",
          borderWidth: 1,
          displayColors: false,
          titleColor: "#9e9e9e",
          titleFont: {
            family: "Inter",
            size: 10,
            weight: 'normal'
          },
          bodyColor: "#e76f51",
          bodyFont: {
            family: "Inter",
            size: 16,
            weight: 'bold'
          },
          footerColor: "#9e9e9e",
          footerFont: {
            family: "Inter",
            size: 10,
            weight: 'normal'
          },
          cornerRadius: 12,
          caretPadding: 10,
          titleMarginBottom: 4,
          footerMarginTop: 4,
          padding: 10,
          yAlign: 'bottom',
          callbacks: {
            title: function(context) {
              return context[0].dataset.label.toUpperCase();
            },
            label: function(context) {
              if (this.formatAsCurrencyValue === true) {
                const parsed = parseFloat(context.formattedValue);
                if (Number.isNaN(parsed)) {
                  return `$${context.formattedValue}`;
                }
                return `$${parsed.toFixed(2)}`;
              }
              return context.formattedValue;
            }.bind(this),
            footer: function(context) {
              return context.map(item => 
                format(
                  parse(item.label, "MMM d, yyyy, h:mm:ss aaaa" , new Date()),
                  "MM/dd"
                )
              );
            },
          }
        }
      }
    };
  }
}
