import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["externalModal", "displayContent"];

  connect() {
    this.productLink = null;
  }

  showProductModal(event) {
    const { productType, productLink, productId, productName } =
      event.currentTarget.dataset;

    this.productLink = productLink;
    this.productName = productName;
    this.productId = productId;
    this.productType = productType;

    this.displayContentTarget.innerText = this.generateSupportText(productType);
    this.externalModalTarget.classList.toggle("hidden");
  }

  closeModal() {
    this.externalModalTarget.classList.add("hidden");
  }

  redirect() {
    this.track_navigation_event();
    window.open(this.productLink, "_blank");

    this.closeModal();
  }

  track_navigation_event() {
    const currentListing = document.querySelector(
      "meta[name='current-listing']"
    );
    var data = {
      host_id: currentListing.dataset.hostId,
      store_id: currentListing.dataset.listingId,
      store_name: currentListing.dataset.listingName,
      item_id: this.productId,
      item_name: this.productName,
      item_type: this.productType,
    };

    window.Analytics.track("Guest navigating to product details", data);
  }

  generateSupportText(productType) {
    const formattedProductType =
      productType === "Amazon" ? "Amazon" : "our partner site";
    const supportText = `You will be redirected to ${formattedProductType}. Any purchases will support your wonderful host.`;
    return supportText;
  }

  preventPropagation(event) {
    event.stopPropagation();
  }
}
