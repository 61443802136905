import { Controller } from '@hotwired/stimulus';
import { Tooltip } from 'flowbite';

export default class extends Controller {
  static targets = ["tableContainer", "brandContent", "verifyBrand","anotherLinkButtton", "loader"]

  connect() {
  }

  searchBrands(event) {
    var currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('name', event.target.value);

    const host_store_url = currentUrl.toString();
    const tableContainer = this.tableContainerTarget;

    Rails.ajax({
      url: host_store_url,
      type: "get",
      dataType: "script",
      success: function(data) {
        tableContainer.innerHTML = data
      },
      error: () => {}
    });
  }

  verify_brand_link(event) {
    var currentUrl = new URL(`${window.location.href}/verify_link`);
    const brandField = this.verifyBrandTarget.value;
    const loader = this.loaderTarget;
    loader.classList.remove('hidden');

    currentUrl.searchParams.set('brand_link', brandField);
    const host_store_url = currentUrl.toString();
    const brandContent = this.brandContentTarget;
  
    Rails.ajax({
      url: host_store_url,
      type: "get",
      dataType: "script",
      success: (data) => {
        brandContent.innerHTML = data;
        loader.classList.add('hidden');
        this.verifyBrandTarget.value = brandField;
      }
    });
  }

  clearSvgTitle(event){
    this.initTollTip(event.currentTarget, event.currentTarget.dataset.tooltipTarget)
    this.element.querySelectorAll("svg title").forEach((title) => {
      title.textContent = "";
    });
  }

  initTollTip(target, triggerId){
    const trigger = document.getElementById(triggerId);
    const tooltip = new Tooltip(trigger, target);
    tooltip.show()
  }
}