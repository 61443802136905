import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['header', 'maxHeader', 'minHeader', 'products'];

  checkAndToggle() {
    const productDivPosition = this.productsTarget?.getBoundingClientRect().top;

    if (productDivPosition < 100) {
      if(this.headerTarget.classList.contains("sticky"))
        return

      let height = this.headerTarget.offsetHeight
      this.headerTarget.classList.add("sticky");
      this.minHeaderTarget?.classList.remove('hidden')
      this.maxHeaderTarget?.classList.add('hidden')

      this.productsTarget.style.marginTop  = `${height - 200}px`
    } else {
      this.productsTarget.style.marginTop  = '0px'
      this.headerTarget.classList.remove("sticky");
      this.minHeaderTarget?.classList.add('hidden')
      this.maxHeaderTarget?.classList.remove('hidden')
    }  
  }
  
}
