import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static targets = ["input", "loadingIcon"];

  connect() {
    this.search = throttle(this.search, 800, { 'leading': false }).bind(this);
  }

  input(_event) {
    this.loadingIconTarget.classList.remove("hidden");
    this.search();
  }

  submitForm(event) {
    event.preventDefault();

    this.search();
  }

  clearInput(event) {
    event.preventDefault();

    this.inputTarget.value = "";

    this.loadingIconTarget.classList.remove("hidden");
    this.element.requestSubmit();
  }

  // Private
  search() {
    this.loadingIconTarget.classList.add("hidden");
    this.element.requestSubmit();
  }
}
