import { post } from '@rails/request.js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['linkUrl', 'sovrnUrl', 'divMonetizationProfile']

  async connect() {
    await displayMonetizationProfile();
  }

  async displayMonetizationProfile() {
    clearMonetizationProfile();

    productLink = this.linkUrlTarget.value;
    if (!productLink || productLink == null || productLink.trim() === '')
      return;

    try {
      this.divMonetizationProfileTarget.innerText = 'Loading...';

      const monetizationMetadata = await checkLinkMonetization(productLink);
      const sovrnUrl = buildSovrnUrl(monetizationMetadata.link, host.affiliate_id);
      
      this.sovrnUrlTarget.value = sovrnUrl;

      if (monetizationMetadata.affiliatable)
        displayMonetizationSuccess();
      else
        displayMonetizationWarning();

    } catch (error) {
      displayMonetizationError();
      console.error(error);
    }
  }

  async checkLinkMonetization(productLink) {
    const validUrl = await isValidUrl(productLink);

    if (!validUrl)
      throw new Error('Invalid URL');

    let url = new URL('https://api.viglink.com/api/link');
    let params = new URLSearchParams(url.search);
    params.append('key', 'abd2f12c91d7c4aaca8fc70de9ae6664');
    params.append('out', productLink);
    params.append('optimize', 'true');
    params.append('format', 'json');
  
    const response = await post(url.toString());
    if (!response.ok)
      throw new Error('Unable to call Sovrn API');

    return response.json;
  }

  async isValidUrl(productLink) {
    const response = await post(productLink);
    return response.ok;
  }

  buildSovrnUrl(productLink, hostAffiliateId) {
    let url = new URL(productLink);
    let params = new URLSearchParams(url.search);
    params.append('cuid', hostAffiliateId);
    params.append('opt', 'true');

    return url.toString();
  }

  clearMonetizationProfile() {
    this.sovrnUrlTarget.value = '';
    this.divMonetizationProfileTarget.innerText = 'Please enter an item link to check for monetization';
  }

  displayMonetizationSuccess() {
    this.divMonetizationProfileTarget.innerText = 'This item is monetizable';
  }

  displayMonetizationWarning() {
    this.divMonetizationProfileTarget.innerText = 'This item is not monetizable';
  }

  displayMonetizationError() {
    this.divMonetizationProfileTarget.innerText = 'There was an error checking for monetization, please double-check the item link and try again';
  }
}
