// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import Carousel from 'stimulus-carousel'
application.register('carousel', Carousel)

import "chartjs-adapter-date-fns";

import SimpleBar from 'simplebar'
application.register('simplebar', SimpleBar)

import ResizeObserver from 'resize-observer-polyfill'
application.register('resize-observer', ResizeObserver)
