import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["initial", "result"];
  static values = {
    duration: { type: Number, default: 5000 }
  };

  connect() {
    this.element.classList.add(...this.startClasses);

    this.timeout = setTimeout(function() {
      this.hide();
    }.bind(this), this.durationValue);
  }

  hide() {
    this.element.classList.remove(...this.startClasses);
    this.element.classList.add(...this.endClasses);
    this.timeout = setTimeout(function() {
      this.element.remove()
    }.bind(this), 500);
  }

  get startClasses() {
    if (this.hasInitialClass) {
      return this.initialClasses;
    }

    return ["opacity-100"];
  }

  get endClasses() {
    if (this.hasResultClass) {
      return this.resultClasses;
    }

    return ["opacity-0"];
  }
}
