import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {
  static targets = ['streetAddress', 'name', 'placeId',
    'previewCard', 'previewPhoto', 'previewPhotoEmpty',
    'previewName', 'previewAddress'
  ];
  static values = {locationLatitude: Number, locationLongitude: Number};

  connect() {
    super.connect();

    this.element['top-spots-controller'] = this;
    this.autocomplete.setBounds(this.autocompleteBounds);
  }

  placeChanged() {
    super.placeChanged()

    this.setHiddenFormFields();
    this.displayPreviewCard();
  }

  cancel(event) {
    event.preventDefault();

    // Clear form and hidden values
    this.element.reset();
    this.element.querySelectorAll('input').forEach(el => el.value = '');

    // Clear and hide preview card
    this.previewCardTarget.classList.add('hidden');
    this.previewPhotoTarget.src = '';
    this.previewPhotoTarget.classList.add('hidden');
    this.previewNameTarget.innerHTML = '';
    this.previewAddressTarget.innerHTML = '';
  }

  // Internal
  setHiddenFormFields() {
    if (this.hasStreetAddressTarget) {
      const addressComponents = this.place.address_components
      const formattedAddress = this.formatAddressComponents(addressComponents)

      this.streetAddressTarget.value = `${formattedAddress.street_number || ""} ${formattedAddress.route || ""}`
    }

    if (this.hasNameTarget) {
      this.nameTarget.value = this.place.name;
    }

    if (this.hasPlaceIdTarget) {
      this.placeIdTarget.value = this.place.place_id;
    }
  }

  displayPreviewCard() {
    this.previewCardTarget.classList.remove('hidden');

    if (this.place.photos && this.place.photos.length > 0) {
      this.previewPhotoTarget.src = this.place.photos[0].getUrl();
      this.previewPhotoTarget.classList.remove('hidden');
    } else {
      this.previewPhotoTarget.src = '';
      this.previewPhotoTarget.classList.add('hidden');
    }

    this.previewNameTarget.innerHTML = this.place.name;
    this.previewAddressTarget.innerHTML = this.place.formatted_address;
  }

  get autocompleteOptions() {
    return {
      fields: [
        'formatted_address', 'address_components', 'geometry',
        'name', 'place_id', 'photo'
      ],
      bounds: this.autocompleteBounds,
      // strictBounds: true,
      componentRestrictions: {
        country: this.countryValue
      }
    };
  }

  get autocompleteBounds() {
    return {
      north: this.locationLatitudeValue + 0.1,
      south: this.locationLatitudeValue - 0.1,
      east: this.locationLongitudeValue + 0.1,
      west: this.locationLongitudeValue - 0.1,
    };
  }
}
