import { Controller } from "@hotwired/stimulus";
import { clamp } from "lodash";

export default class extends Controller {
  static targets = ["number"];

  connect() {
    this.min = parseInt(this.numberTarget.min);
    this.max = parseInt(this.numberTarget.max);
    this.counter = this.min;

    this.updateValue(0);
  }

  increment(event) {
    event.preventDefault();
    this.updateValue(1);
  }

  decrement(event) {
    event.preventDefault();
    this.updateValue(-1);
  }

  updateValue(amount) {
    this.counter = clamp(this.counter + amount, this.min, this.max);
    this.numberTarget.value = this.counter;
  }
}
