import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "selectedList", "unselectedList", "hiddenInput", "selectButton", "deselectButton",
    "unselectedExclusions", "selectedExclusions", 'form'
  ];
  static values = { fieldId: String, fieldName: String };

  connect(event) {
    // Lets append `filter` param to the request
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const filter = urlParams.get('filter')
    if (filter) {
      const inputElement = document.createElement("input");
      inputElement.setAttribute("type", "hidden");
      inputElement.setAttribute("name", 'filter');
      inputElement.setAttribute("value", filter);
      this.formTarget.appendChild(inputElement)
    }

    this.prepareEmptyInput()
  }

  // Actions
  select(event) {
    const items = this.checkedOptionsFor(this.unselectedListTarget);
    this.selectItems(event, items);
  }

  deselect(event) {
    const items = this.checkedOptionsFor(this.selectedListTarget);
    this.deselectItems(event, items);
  }

  selectAll(event) {
    const items = this.optionsFor(this.unselectedListTarget);
    this.selectItems(event, items);
  }

  deselectAll(event) {
    const items = this.optionsFor(this.selectedListTarget);
    this.deselectItems(event, items);
  }

  filterUnselected(event) {
    const items = this.optionsFor(this.unselectedListTarget);
    const query = event.target.value;

    this.filterItems(items, query);
  }

  filterSelected(event) {
    const items = this.optionsFor(this.selectedListTarget);
    const query = event.target.value;

    this.filterItems(items, query);
  }

  updateButtonState(_event) {
    const checkedUnselected = this.checkedOptionsFor(this.unselectedListTarget);
    if (checkedUnselected.length > 0) {
      // Enable select button
      this.selectButtonTarget.disabled = false;
    } else {
      this.selectButtonTarget.disabled = true;
    }

    const checkedSelected = this.checkedOptionsFor(this.selectedListTarget);
    if (checkedSelected.length > 0) {
      // Enable deselect button
      this.deselectButtonTarget.disabled = false;
    } else {
      this.deselectButtonTarget.disabled = true;
    }
  }

  // Internal
  selectItems(event, items) {
    event.preventDefault();

    this.addToHiddenInput(items);
    this.moveItemsTo(items, this.selectedListTarget);

    // Add to unselected exclusions so that a search in the unselected list doesn't cause it to reappear.
    this.addToExclusions(this.unselectedExclusionsTarget, items)
    this.removeFromExclusions(this.selectedExclusionsTarget, items)
  }

  deselectItems(event, items) {
    event.preventDefault();

    this.removeFromHiddenInput(items);
    this.moveItemsTo(items, this.unselectedListTarget);

    // Add to selected exclusions so that a search in the selected list doesn't cause it to reappear.
    this.addToExclusions(this.selectedExclusionsTarget, items)
    this.removeFromExclusions(this.unselectedExclusionsTarget, items);
  }

  optionsFor(listTarget) {
    return Array.from(listTarget.querySelectorAll("label"));
  }

  checkedOptionsFor(listTarget) {
    return this.optionsFor(listTarget).filter(item => this.isChecked(item));
  }

  isChecked(item) {
    const checkbox = item.querySelector("input:checked")

    return !!checkbox;
  }

  moveItemsTo(items, target) {
    items.forEach(element => {
      // NOTE: prepend() appears to work similary to appendChild() where it removes the node from its current parent
      // when adding to its new parent. We don't need to remove the element from its original location here.
      target.prepend(element);
    });

    this.uncheckAllItems();
    this.updateButtonState();
  }

  uncheckAllItems() {
    this.unselectedListTarget.querySelectorAll("input").forEach(item => item.checked = false);
    this.selectedListTarget.querySelectorAll("input").forEach(item => item.checked = false);
  }

  addToHiddenInput(items) {
    if (items.length <= 0) {
      return;
    }

    // Remove any blank placeholder value
    const emptyInputElement = this.hiddenInputTarget.querySelector('input[value=""]');
    if (emptyInputElement && emptyInputElement.parentNode) {
      emptyInputElement.parentNode.removeChild(emptyInputElement);
    }

    items.forEach(element => {
      const value = element.dataset.value;
      this.appendHiddenValue(value);
    });
  }

  appendHiddenValue(value) {
    const inputElement = document.createElement("input");
    inputElement.setAttribute("type", "hidden");
    inputElement.setAttribute("id", this.fieldIdValue);
    inputElement.setAttribute("name", this.fieldNameValue);
    inputElement.setAttribute("value", value);

    this.hiddenInputTarget.appendChild(inputElement);
  }

  removeFromHiddenInput(items) {
    items.forEach(element => {
      const value = element.dataset.value;
      const inputElement = this.hiddenInputTarget.querySelector(`input[value="${value}"]`);

      if (inputElement && inputElement.parentNode) {
        inputElement.parentNode.removeChild(inputElement);
      }
    });

    this.prepareEmptyInput();
  }

  prepareEmptyInput() {
    const inputElements = this.hiddenInputTarget.querySelectorAll("input");

    if (inputElements.length === 0) {
      // If the user has removed all items, we need to include a blank placeholder value so that Rails knows the user
      // intends to clear the value of the field. Otherwise, the field will not be sent in the form and no updates
      // will be made.
      this.appendHiddenValue('');
    }
  }

  filterItems(items, query) {
    const lowercaseQuery = query.toLowerCase();

    items.forEach(el => {
      if (el.dataset.name) {
        if (el.dataset.name.toLowerCase().includes(lowercaseQuery)) {
          el.classList.remove("hidden");
        } else {
          el.classList.add("hidden");
        }
      }
    });
  }

  // Handle search exclusion lists
  addToExclusions(exclusionsContainer, items) {
    items.forEach(element => {
      const value = element.dataset.value;
      const inputElement = document.createElement("input");
      inputElement.setAttribute("type", "hidden");
      inputElement.setAttribute("id", "excluded_ids");
      inputElement.setAttribute("name", "excluded_ids[]");
      inputElement.setAttribute("value", value);

      exclusionsContainer.appendChild(inputElement);
    });
  }

  removeFromExclusions(exclusionsContainer, items) {
    items.forEach(element => {
      const value = element.dataset.value;
      const inputElement = exclusionsContainer.querySelector(`input[value="${value}"]`);

      if (inputElement && inputElement.parentNode) {
        inputElement.parentNode.removeChild(inputElement);
      }
    });
  }
}
