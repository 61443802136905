import { post } from "@rails/request.js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["url", "preview", "name", "description"];
  static values = { loading: String };

  connect() {
    this.generate_preview(false);
  }

  generate_preview(setEditableFields = true) {
    const amazonProductUrl = this.urlTarget.value;

    if (amazonProductUrl.length === 0) {
      this.previewTarget.innerHTML = `
        <div class="flex-auto rounded-2xl bg-white shadow-card false my-8 p-4 text-center">
            <div class="pt-8 pb-5 px-5 text-body1 text-black/[0.26] font-bold">
            Please enter an Amazon URL to see the item preview
          </div>
        </div>
      `;
      return;
    }

    try {
      const asin = amazonProductUrl
        .match(/(?:[/dp/]|$)([A-Z0-9]{10})/g)[0]
        .replace(/[^0-9a-z]/gi, "");
      this.previewTarget.innerHTML = `<div class='flex my-8'><div class='m-auto'><img src="${this.loadingValue}" /></div></div>`;
      this.retreive_product_preview(asin, setEditableFields);
    } catch {
      this.previewTarget.innerHTML = this.buildErrorTemplate(
        "Broken Link",
        "The URL you have entered is invalid,<br/>please paste a valid Amazon item URL"
      );
    }
  }

  async retreive_product_preview(asin, setEditableFields) {
    const response = await post("/products/amazon_product_preview", {
      body: JSON.stringify({ asin: asin }),
      responseKind: "json",
    });

    if (response.ok) {
      const body = await response.json;
      this.previewTarget.innerHTML = body.preview;

      // Don't set values on the initial connect, so that we don't override the user's custom name or description.
      if (setEditableFields) {
        this.nameTarget.value = body.product.name;

        if (this.hasDescriptionTarget) {
          this.descriptionTarget.value = body.product.description;
        }
      }
    } else {
      this.previewTarget.innerHTML = this.buildErrorTemplate(
        "Error",
        "Unable to retrieve item details, please try again later."
      );
    }
  }

  buildErrorTemplate(title, message) {
    return `
      <div class='flex p-[44px] my-8 shadow-card text-center'>
        <div class='m-auto'>
          <h2 class='text-body1 text-error font-bold'>${title}</h2>
          <p>${message}</p>
        </div>
      </div>
    `;
  }
}
